import type { Invoice } from '@/components/billing/invoice/card/types'
import {
	API_BASE,
	API_ENDPOINT_ACCOUNT_INVOICES,
	API_ENDPOINT_ACCOUNT_INVOICE,
	API_ENDPOINT_ACCOUNT_INVOICE_PAY,
	API_ENDPOINT_ACCOUNT_INVOICES_PAST_DUE,
	API_ENDPOINT_PORTAL_INVOICE_UPDATE,
	API_ENDPOINT_PORTAL_CREATE_NEW_INVOICE,
	API_ENDPOINT_PORTAL_INVOICE_REFUND
} from '@/constants/index.js'
import type {
	InvoiceApiResponse,
	MappedInvoice,
	MappedInvoicePayment,
	UpdateInvoiceData,
	AccountInvoicesGetApiResponseInvoiceItem,
	MappedAccountInvoice
} from '@/components/account/invoices/types'
import { CREDIT_CARD, BANK_ACCOUNT, CHECK_OR_OFFLINE_PAYMENT } from '@/constants/payment'
import { mapInvoiceApiResponse, mapAccountInvoiceGetApiResponseInvoice } from '@/utils/individualInvoiceMap'
import type { TransactionOption } from '@/components/account/invoices/types'
import { useAuth } from '@/composables/useAuth'
import { formatDate } from '@/utils/formatDate'
import { varNameToDisplayCase } from '@/utils/convertTextCasing'
import { ref } from 'vue'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import { useErrorReporter } from '@/composables/useErrorReporter'

const { bugsnagReport } = useErrorReporter()

const invoice = ref<MappedInvoice | null>(null)
const balanceDue = ref('')
const nextRecurring = ref('')
const totalBalance = ref('')
const loading = ref(true)
const singleInvoiceLoading = ref(true)
const invoices = ref<MappedAccountInvoice[]>([])

export function useInvoices () {
	const { t } = useI18n()
	const { addToast } = useToast()
	const pastDueInvoices = ref<Invoice[]>([])
	const totalOverdueAmount = ref(0)
	const showEmptyState = ref(false)
	const perPage = ref(10) // Default to 10 invoices per page
	const totalInvoices = ref(0)
	const currentPage = ref(1)
	const totalPages = computed(() => {
		return Math.ceil(totalInvoices.value / perPage.value)
	})

	const nextPaymentDue = computed(() => {
		return invoices.value.filter((invoice: MappedAccountInvoice) => {
			return invoice.amountDue > 0 && new Date(invoice.dueDate) > new Date()
		}).sort((a: MappedAccountInvoice, b: MappedAccountInvoice) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())[0] || null
	})
	const nextPaymentDueDate = computed(() => {
		return formatDate(nextPaymentDue.value?.dueDate)
	})
	const nextPaymentDueAmount = computed(() => {
		return nextPaymentDue.value?.totalAmount || 0
	})
	const hasNextPayment = computed(() => !!nextPaymentDue.value)

	const nextPaymentInvoiceId = computed(() => nextPaymentDue.value?.id)

	const hasInvoices = computed(() => invoices.value.length > 0)

	const hasOverdue = computed(() => hasInvoices.value && parseInt(balanceDue.value) > 0)

	const invoiceTransactionOptions = computed((): TransactionOption[] => {
		const getPaymentLabel = (payment: MappedInvoicePayment): string => {
			const paymentPrefix = varNameToDisplayCase(payment.paymentType)

			switch (payment.paymentType) {
			case CREDIT_CARD:
				return t('invoices-page.transaction-select.options.credit-card', { lastFour: payment.ccLastFour })
			case BANK_ACCOUNT:
				return t('invoices-page.transaction-select.options.bank-account', { lastFour: payment.bankLastFour })
			case CHECK_OR_OFFLINE_PAYMENT:
				return t('invoices-page.transaction-select.options.check-or-offline')
			default:
				return paymentPrefix
			}
		}
		const nonRefundedTransactions: MappedInvoicePayment[] | undefined = invoice.value?.payments.filter((payment: MappedInvoicePayment) => !payment.refunded) || []
		return [
			{
				id: '',
				name: t('invoices-page.transaction-select.options.select-transaction'),
				disabled: true
			},
			...nonRefundedTransactions?.map((payment: MappedInvoicePayment) => {
				return {
					id: payment.invoicePaymentId.toString(),
					name: `$${payment.amountCharged.toFixed(2)} - ${formatDate(payment.createdTime)} (${getPaymentLabel(payment)})`,
					disabled: payment.paymentType === CHECK_OR_OFFLINE_PAYMENT || payment.chargeStatus !== 'Ok'
				}
			})
		]
	})

	const { accountId, fullName, email, emailId } = useAuth()

	const clearInvoicesData = () => {
		invoices.value = []
		totalInvoices.value = 0
		balanceDue.value = ''
		nextRecurring.value = ''
		totalBalance.value = ''
	}

	const getInvoices = async (data?: { accountIdArg?: string, limit?: number, page?: number }) => {
		const { accountIdArg, limit = 10, page = 1 } = data || {}
		if (limit) {
			perPage.value = limit
		}
		if (page) {
			currentPage.value = page
		}
		loading.value = true

		let account: string

		if (!accountIdArg) {
			account = accountId?.value as string
		} else {
			account = accountIdArg
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_INVOICES.replace('{account-id}', account)}`

		try {
			await $fetch(fullPath, {
				method: 'POST',
				credentials: 'include',
				body: {
					page,
					limit: perPage.value
				},
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						if (response._data?.invoices?.length) {
							invoices.value = response._data.invoices?.map((invoice: AccountInvoicesGetApiResponseInvoiceItem) => mapAccountInvoiceGetApiResponseInvoice(invoice)) || []
							totalInvoices.value = response._data.count
							balanceDue.value = response._data.balanceDue
							nextRecurring.value = response._data.nextRecurring
							totalBalance.value = response._data.totalBalance
							showEmptyState.value = false
						} else {
							clearInvoicesData()
							showEmptyState.value = true
						}
					} else {
						bugsnagReport({
							error: new Error(`getInvoices: No data or failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors,
							user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getInvoices - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	const getInvoice = async (data: { invoiceId: string, accountId?: string }) => {
		singleInvoiceLoading.value = true

		let account: string

		if (!data?.accountId) {
			account = accountId?.value as string
		} else {
			account = data.accountId
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_INVOICE.replace('{account-id}', account).replace('{invoice-id}', data?.invoiceId)}`

		try {
			await $fetch<InvoiceApiResponse>(fullPath, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						if (response._data) {
							invoice.value = mapInvoiceApiResponse(response._data)
							showEmptyState.value = false
							loading.value = false
						} else {
							showEmptyState.value = true
						}
					} else {
						bugsnagReport({
							error: new Error('getInvoice - no data'),
							showToast: true,
							metaData: { invoiceId: data?.invoiceId, response },
							user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getInvoice - catch: ' + (e as Error).toString()),
				showToast: true,
				metaData: { invoiceId: data?.invoiceId },
				user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
			})
		} finally {
			singleInvoiceLoading.value = false
		}
	}

	const getPastDue = async (data?: { accountIdArg?: string, limit?: number, page?: number }) => {
		const { accountIdArg, limit = 10, page = 0 } = data || {}
		loading.value = true

		let account: string

		if (!accountIdArg) {
			account = accountId?.value as string
		} else {
			account = accountIdArg
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_INVOICES_PAST_DUE.replace('{account-id}', account).replace('{limit}', limit.toString()).replace('{page}', page.toString())}`

		try {
			await $fetch(fullPath, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						loading.value = false
						pastDueInvoices.value = response._data?.invoices?.invoices?.map((invoice: InvoiceApiResponse) => mapInvoiceApiResponse(invoice)) || []
						totalOverdueAmount.value = response._data?.totalOverdueAmount || 0
					} else {
						bugsnagReport({
							error: new Error(`getInvoices: No data or failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors,
							user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getInvoices - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: account, emailId: emailId.value, email: email.value, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	const updateInvoice = async (data: { invoiceId: string, accountId: string, payload: UpdateInvoiceData}) => {
		const { invoiceId, accountId, payload } = data
		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_INVOICE_UPDATE.replace('{account-id}', accountId).replace('{invoice-id}', invoiceId)}`
		try {
			await $fetch(fullPath, {
				method: 'PUT',
				credentials: 'include',
				body: payload,
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						singleInvoiceLoading.value = false
						getInvoice({ invoiceId, accountId })
					} else {
						bugsnagReport({
							error: new Error(`updateInvoice: Failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('updateInvoice - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			singleInvoiceLoading.value = false
		}
	}

	const refundInvoice = async (data: { invoiceId: string, invoicePaymentId: string, accountId: string, amount: number, note: string }) => {
		const { invoiceId, accountId, invoicePaymentId, amount, note } = data

		const invoicePayment = invoice.value?.payments?.find((payment: MappedInvoicePayment) => payment.invoicePaymentId.toString() === invoicePaymentId)
		if (invoicePayment?.chargeStatus !== 'Ok') {
			addToast({
				title: t('invoices-page.toasts.error.refunding-failed-payment.title'),
				message: t('invoices-page.toasts.error.refunding-failed-payment.message'),
				notificationType: 'error'
			})
			return
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_INVOICE_REFUND.replace('{account-id}', accountId).replace('{invoice-id}', invoiceId).replace('{invoice-payment-id}', invoicePaymentId)}`
		try {
			await $fetch(fullPath, {
				method: 'POST',
				credentials: 'include',
				body: {
					amount,
					note
				},
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						singleInvoiceLoading.value = false
						getInvoice({ invoiceId, accountId })
					} else {
						throw new Error(response._data?.error || response._data?.errors?.[0] || 'Error refunding invoice')
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('refundInvoice - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			singleInvoiceLoading.value = false
		}
	}

	const createInvoice = async (data: { accountId: string, payload: UpdateInvoiceData }) => {
		const { accountId, payload } = data
		loading.value = true
		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_CREATE_NEW_INVOICE.replace('{account-id}', accountId)}`
		try {
			await $fetch(fullPath, {
				method: 'POST',
				credentials: 'include',
				body: payload,
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						loading.value = false
						addToast({
							title: t('invoices-page.toasts.success.created-invoice.title'),
							message: t('invoices-page.toasts.success.created-invoice.message'),
							notificationType: 'success'
						})
					} else {
						throw new Error(response._data?.error || response._data?.errors[0])
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('Create Invoice - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			loading.value = false
		}
	}

	const hitPayToActivationBilling = async (data: {accountId: string, invoiceId: string}) => {
		const { accountId, invoiceId } = data
		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_INVOICE_PAY.replace('{account-id}', accountId).replace('{invoice-id}', invoiceId)}`
		await $fetch(fullPath, {
			method: 'POST',
			credentials: 'include',
			body: {
				offlinePayment: true
			},
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('hitPayToActivationBilling - catch: ' + (e as Error).toString()),
				showToast: true
			})
		})
	}

	return {
		getInvoices,
		currentPage,
		totalInvoices,
		totalPages,
		invoices,
		loading,
		showEmptyState,
		getInvoice,
		invoice,
		hasInvoices,
		hasOverdue,
		invoiceTransactionOptions,
		getPastDue,
		pastDueInvoices,
		totalOverdueAmount,
		nextPaymentDue,
		nextPaymentDueDate,
		nextPaymentDueAmount,
		hasNextPayment,
		nextPaymentInvoiceId,
		perPage,
		updateInvoice,
		refundInvoice,
		createInvoice,
		balanceDue,
		nextRecurring,
		totalBalance,
		singleInvoiceLoading,
		hitPayToActivationBilling
	}
}
